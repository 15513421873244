<template>
  <div class="pt-2">
    <v-row>
      <v-col cols="6">
        <v-select
          :items="['USD', 'EUR', 'RUR', 'UAH']"
          label=""
          solo
          v-model="form.from"
          hide-details
          dense
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          :items="['USD', 'EUR', 'RUR', 'UAH']"
          label=""
          solo
          v-model="form.to"
          hide-details
          dense
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label=""
          placeholder=""
          v-model="form.amount"
          solo
          hide-details
          dense
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <div class="title pt-1">= 30</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        from: "USD",
        to: "UAH",
        amount: 1,
      },
    };
  },
};
</script>